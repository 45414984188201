import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
// import { Helmet } from "react-helmet";
import {
  Heading,
  Text,
  Box,
  Link,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import SinglePageHeadingSection from "../components/SinglePageHeadingSection";
import { RichText } from "prismic-reactjs";
import { standardToName } from "../utils/standardToName";
import { htmlSerializer } from "../utils/htmlSerializer";
import Container from "../components/PageContentContainer";
import { generateTableOfContent } from "../utils/generateTableOfContent";
import Seo from "../components/SEO";
import Testimonials from "../components/Testimonials";
import OurClients from "../components/OurClients";

const Cta = ({ standard }) => {
  return (
    <Box>
      <OurClients />
      <Testimonials />
      <Box
        maxW="7xl"
        w={{ md: "3xl", lg: "4xl" }}
        mx={{ base: 3, lg: "auto" }}
        my={{ base: 3, lg: 6 }}
        bg={"#2b2f69"}
        boxShadow="xl"
        py={{ base: 3, lg: 6 }}
        px={{ base: 4, lg: 8 }}
        rounded={"md"}
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        alignItems={"center"}
        justifyContent={{ lg: "space-between" }}
      >
        <Heading as="h3" color={"gray.100"}>
          <Text as="span" display="block" textAlign={"center"}>
            Get {standard} Certification from us
          </Text>
        </Heading>
        <Link
          display="block"
          px={5}
          py={3}
          border="solid transparent"
          fontWeight="bold"
          rounded="md"
          shadow="md"
          bg="white"
          textDecoration="none"
          href={"#contact-us"}
          mt={3}
        >
          Apply Now
        </Link>
      </Box>
    </Box>
  );
};

const Page = ({ data: queryResult }) => {
  const { data, uid } = queryResult.allPrismicCertifications.edges[0].node;
  const standard = uid.split("-")[1] || uid.split("-")[0];
  return (
    <>
      <Layout>
        <SinglePageHeadingSection
          imgSrc={data.featured_image.url}
          title={data.title.text}
          subtitle={standardToName(standard)}
        />
        <Seo title={data.title.text} />

        {/* <Cta standard={data.title.text} /> */}
        <Container>
          <Stack
            direction={"row"}
            divider={
              <StackDivider
                backgroundColor={"gray.500"}
                display={{ base: "none", lg: "block" }}
              />
            }
          >
            <Box flex={1} pe={"1.5rem"}>
              <RichText
                render={data.content.richText}
                htmlSerializer={htmlSerializer}
              />
            </Box>
            <Box
              width={"300px"}
              ps={"1.5rem"}
              position={"relative"}
              display={{ base: "none", lg: "block" }}
            >
              <Box position={"sticky"} top="1rem">
                <Heading as="h6" size="lg" color="#2b2f69">
                  Table of Content{" "}
                </Heading>
                {generateTableOfContent(data.content.richText)}
              </Box>
            </Box>
          </Stack>
          {/* <RichText
          render={data.content.richText}
          htmlSerializer={htmlSerializer}
        /> */}
        </Container>
        <Cta standard={data.title.text} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query CertificatePageData($slug: String) {
    allPrismicCertifications(filter: { uid: { eq: $slug } }) {
      edges {
        node {
          data {
            content {
              richText
            }
            title {
              text
            }
            featured_image {
              url
            }
          }
          uid
        }
      }
    }
  }
`;

export default Page;
